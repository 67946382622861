import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate, formatMoney } from 'helpers/helpers'
import { useSelector } from 'react-redux'

function BalancePrint({ data, filterData, printRef }) {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	return (
		<>
			<div className="card" ref={printRef}>
				<div className="card-body p-0">
					<div className="table-responsive">
						<table className="table balance-table">
							<thead>
								<tr>
									<th rowSpan="2" style={{ width: '10%' }}>{t('POS')}</th>
									<th rowSpan="2" style={{ width: '3%' }}>{t('FUEL')}</th>
									<th colSpan="3" style={{ width: '20%' }} className="after-101 text-center" scope="colgroup">Сальдо на: {formatDate(filterData.start_date)} га сальдо  </th>
									<th colSpan="3" style={{ width: '20%' }} className="after-101 text-center" scope="colgroup">Кирим (приход)</th>
									<th colSpan="3" style={{ width: '20%' }} className="after-101 text-center" scope="colgroup">Чиким (Расход)</th>
									<th colSpan="3" style={{ width: '20%' }} className="after-101 text-center" scope="colgroup">Сальдо на: {formatDate(filterData.end_date)} га сальдо  </th>
									<th rowSpan="2" style={{ width: '3.3%' }}>Махсулот айланмаси <br /> (Товарооборот)</th>
									<th rowSpan="2" style={{ width: '3.3%' }}>Жами даромад <br /> (Валовый доход)</th>
								</tr>
								<tr>
									<th>Литр</th>
									<th>Кг</th>
									<th>Сумма</th>

									<th>Литр</th>
									<th>Кг</th>
									<th>Сумма</th>

									<th>Литр</th>
									<th>Кг</th>
									<th>Сумма</th>


									<th>Литр</th>
									<th>Кг</th>
									<th>Сумма</th>
								</tr>
							</thead>
							<tbody>
								{data.fuels?.map((item, index) => (
									<tr key={index}>
										<td className="text-start">{reduxAccount.pos_name}</td>
										<td className="text-start">{item.name}</td>

										<td>{formatMoney(item.start_prev_balance)}</td>
										<td>{formatMoney(Math.round(Number(item.start_prev_balance) - (Number(item.start_prev_balance) * 0.13)))}</td>
										<td>{formatMoney(item.start_amount)}</td>

										<td>{formatMoney(item.total_mass_liter)}</td>
										<td>{formatMoney(Math.round(Number(item.total_mass_liter) - (Number(item.total_mass_liter) * 0.13)))}</td>
										<td>{formatMoney(item.total_mass_amount)}</td>

										<td>{formatMoney(item.total_sold)}</td>
										<td>{formatMoney(Math.round(Number(item.total_sold) - (Number(item.total_sold) * 0.13)))}</td>
										<td>{formatMoney(item.total_amount)}</td>

										<td>{formatMoney(item.end_next_balance)}</td>
										<td>{formatMoney(Math.round(Number(item.end_next_balance) - (Number(item.end_next_balance) * 0.13)))}</td>
										<td>{formatMoney(item.end_amount)}</td>

										<td>{formatMoney(item.turnover)}</td>
										<td></td>
									</tr>
								))}
								<tr className="tr-bold">
									<td className="text-start" colSpan={2}>{t('TOTAL')}: </td>

									<td>{formatMoney(data.total.start_prev_balance)}</td>
									<td>{formatMoney(Math.round(Number(data.total.start_prev_balance) - (Number(data.total.start_prev_balance) * 0.13)))}</td>
									<td>{formatMoney(data.total.start_amount)}</td>

									<td>{formatMoney(data.total.total_mass_liter)}</td>
									<td>{formatMoney(Math.round(Number(data.total.total_mass_liter) - (Number(data.total.total_mass_liter) * 0.13)))}</td>
									<td>{formatMoney(data.total.total_mass_amount)}</td>

									<td>{formatMoney(data.total.total_sold)}</td>
									<td>{formatMoney(Math.round(Number(data.total.total_sold) - (Number(data.total.total_sold) * 0.13)))}</td>
									<td>{formatMoney(data.total.total_amount)}</td>

									<td>{formatMoney(data.total.end_next_balance)}</td>
									<td>{formatMoney(Math.round(Number(data.total.end_next_balance) - (Number(data.total.end_next_balance) * 0.13)))}</td>
									<td>{formatMoney(data.total.end_amount)}</td>

									<td>{formatMoney(data.total.turnover)}</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="balance-card">
						<div className="row">
							<div className="col-md-8">
								Махсулот айланмаси (товарооборот)
							</div>
							<div className="col-md-4 text-center">
								{formatMoney(data.total.turnover)}
							</div>
						</div>
					</div>

					<div className="balance-card">
						<div className="row">
							<div className="col-md-8">
								Жами даромад (Валовый доход)
							</div>
							<div className="col-md-4 text-center">
								{0}
							</div>
						</div>
					</div>

					<div className="balance-card">
						<div className="row">
							<div className="col-md-8">
								Солик (Налог)
							</div>
							<div className="col-md-4 text-center">
								{formatMoney(data.total.total_tax)}
							</div>
						</div>
					</div>

					<div className="balance-card">
						<div className="row">
							<div className="col-md-8">
								Сумма без налога
							</div>
							<div className="col-md-4 text-center">
								{formatMoney(Number(data.total.turnover) - Number(data.total.total_tax))}
							</div>
						</div>
					</div>

					{data?.total?.payments?.map((item, index) => (
						<div className="balance-card" key={index}>
							<div className="row">
								<div className="col-md-8">
									{item.payment_name}
								</div>
								<div className="col-md-4 text-center">
									{formatMoney(Number(item.total_amount))}
								</div>
							</div>
						</div>
					))
					}

				</div>
			</div>
		</>
	)
}

export default BalancePrint