import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { formatDate, handleFocus, sortByNumber } from 'helpers/helpers'
import { DELETE, GET, POST, PUT } from 'helpers/api'
import DeleteModal from "pages/parts/DeleteModal"

function Index({ showModal, setShowModal }) {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({
		'name': '',
		'fuel_id': '',
		'storage_tank_id': '',
		'quantity': 1,
	})

	const [fuelDispensers, setFuelDispensers] = useState([])
	const [fuels, setFuels] = useState([])
	const [allTanks, setAllTanks] = useState([])
	const [tanks, setTanks] = useState([])
	const [data, setData] = useState([])

	const [showCreateModal, setShowCreateModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }
		if (!selectedItemCopy.id) {
			selectedItemCopy.fuels = fuelDispensers;
			await POST('/fuel-dispenser', selectedItemCopy)
			setFuelDispensers([])
		} else {
			await PUT('/fuel-dispenser', selectedItemCopy)
		}

		setSelectedItem({})
		setShowModal(true)
		setShowCreateModal(false)
		// getData()
	}

	function addToList() {
		var arr = [...fuelDispensers]
		for (let i = 0; i < selectedItem.quantity; i++) {
			arr.push({
				'name': i + 1,
				'fuel_id': selectedItem.fuel_id,
				'storage_tank_id': selectedItem.storage_tank_id,
				'tanks': tanks,
			})
		}

		setFuelDispensers(arr)
		setSelectedItem({ ...selectedItem, quantity: 1 })
	}

	function deleteFuelDispenser(index) {
		var fuelDispensersCopy = [...fuelDispensers]
		fuelDispensersCopy.splice(index, 1)
		setFuelDispensers(fuelDispensersCopy)
	}

	async function deleteItem() {
		var selectedItemCopy = { ...selectedItem }
		await DELETE('/fuel-dispenser/' + selectedItemCopy.id, {})
		setSelectedItem({})
		setShowDeleteModal(false)
		getData()
	}

	function openModal(item) {
		var selectedItemCopy = { ...item }
		if (item?.id) {
			var localTanks = allTanks.filter(el => el.fuel_id === item.fuel_id)
			setTanks(localTanks)
		}
		setSelectedItem({ ...selectedItemCopy, quantity: 1 })
		setShowModal(false)
		setShowCreateModal(true)
	}

	function openDeleteModal(item) {
		var selectedItemCopy = { ...item }
		setSelectedItem({ id: selectedItemCopy.id })
		setShowDeleteModal(true)
	}

	function closeModal() {
		setSelectedItem({
			'name': '',
			'fuel_id': '',
			'storage_tank_id': '',
			'quantity': 1,
		})
		setShowModal(true)
		setShowCreateModal(false)
		setFuelDispensers([])
	}

	function handleChangeDispenser(e, index) {
		var fuelDispensersCopy = [...fuelDispensers]
		fuelDispensersCopy[index]['name'] = e.target.value
		setFuelDispensers(fuelDispensersCopy)
	}

	function selectDispenserFuel(option, index) {
		var fuelDispensersCopy = [...fuelDispensers]
		console.log(option);

		fuelDispensersCopy[index]['fuel_id'] = option.id
		fuelDispensersCopy[index]['storage_tank_id'] = option.tanks.length > 0 ? option.tanks[0]?.id : ''
		fuelDispensersCopy[index]['tanks'] = option.tanks
		setFuelDispensers(fuelDispensersCopy)
	}

	function selectDispenserTank(option, index) {
		var fuelDispensersCopy = [...fuelDispensers]
		fuelDispensersCopy[index]['storage_tank_id'] = option.id
		setFuelDispensers(fuelDispensersCopy)
	}

	function selectFuel(option) {
		var localTanks = allTanks.filter(item => item.fuel_id === option.id)
		setTanks(localTanks)
		setSelectedItem({ ...selectedItem, 'fuel_id': option?.id, 'storage_tank_id': localTanks[0]?.id, tanks: localTanks })
	}

	async function getData() {
		const response = await GET('/fuel-dispenser', {}, { loader: true })
		if (response) {
			setData(sortByNumber(response))
		}
	}

	async function getFuels() {
		const response = await GET('/fuel-helper')
		if (response) {
			setFuels(response)
			selectFuel(response[0])
		}
	}

	async function getTanks() {
		const response = await GET('/storage-tank-helper')
		if (response) {
			setAllTanks(response)
		}
	}

	useEffect(() => {
		if (showModal) {
			getData()
			getFuels()
			getTanks()
		}
	}, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={showModal} animation={false} size='xl'
				centered dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center justify-content-between w-100">
						<h4>{t('FUEL_DISPENSER')}</h4>
						<button className="btn-rounded btn btn-primary me-2" onClick={() => openModal()}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</button>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="card">
						<div className="card-body p-0">
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th className="text-center">{t('FUEL_DISPENSER')} {t('NAME')}</th>
											<th className="text-center">{t('FUEL')}</th>
											<th className="text-center">{t('STORAGE_TANK')}</th>
											<th className="text-center">{t('DATE')}</th>
											<th className="text-center">{t('ACTION')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{item.name}</td>
												<td className="text-center">{item.fuel_name}</td>
												<td className="text-center">{item.tank_name}</td>
												<td className="text-center">{formatDate(item.created_at)}</td>
												<td className="d-flex justify-content-center">
													<div className="table-action-button table-action-primary-button me-2" onClick={() => openModal(item)}>
														<i className="uil-edit-alt"></i>
													</div>
													<div className="table-action-button table-action-danger-button" onClick={() => openDeleteModal(item)}>
														<i className="uil-trash-alt"></i>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/* MODAL */}
			<Modal show={showCreateModal} animation={false} centered dialogClassName="update-modal-width" onHide={() => closeModal()} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('CREATE')}</span>
							:
							<span>{t('EDIT')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{selectedItem.id ?
						<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
							<div className="form-group">
								<label>{t('NAME')}</label>
								<input type="text" className="form-control" name="name" value={selectedItem.name}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
							</div>
							<div className="form-group">
								<label>{t('FUEL')}</label>
								<Select
									options={fuels}
									value={fuels.find(option => option.id === selectedItem.fuel_id)}
									onChange={(option) => selectFuel(option)}
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
							<div className="form-group">
								<label>{t('STORAGE_TANK')}</label>
								<Select
									options={tanks}
									value={tanks.find(option => option.id === selectedItem.storage_tank_id)}
									onChange={(option) => setSelectedItem({ ...selectedItem, 'storage_tank_id': option.id })}
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
							<div className="d-flex w-100 mt-3">
								<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
								<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
							</div>
						</form>
						:
						<div>
							<table className="table table-bordered" style={{ overflow: 'auto' }}>
								<thead>
									<tr>
										<th style={{ width: '20%' }} className="text-center">{t('FUEL_DISPENSER')} №</th>
										<th style={{ width: '30%' }} className="text-start">{t('FUEL')}</th>
										<th style={{ width: '30%' }} className="text-start">{t('STORAGE_TANK')}</th>
										<th style={{ width: '15%' }}></th>
									</tr>
								</thead>
								<tbody>
									{fuelDispensers?.map((item, index) => (
										<tr key={index}>
											<td className="td-input text-center">
												<input type="text" className="form-control text-center" name="quantity" value={item.name} autoComplete="off"
													onChange={(e) => handleChangeDispenser(e, index)} onFocus={handleFocus} />
											</td>
											<td className="td-input text-start" style={{ height: '30px' }}>
												<Select
													options={fuels}
													value={fuels.find(option => option.id === item.fuel_id)}
													onChange={(option) => selectDispenserFuel(option, index)}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
													classNamePrefix="react-select"
												/>
											</td>
											<td className="td-input text-start" style={{ height: '30px' }}>
												<Select
													options={item?.tanks}
													value={item?.tanks?.find(option => option.id === item.storage_tank_id)}
													onChange={(option) => selectDispenserTank(option, index)}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
													classNamePrefix="react-select"
												/>
											</td>
											<td>
												<div className="d-flex justify-content-center"
													onClick={() => deleteFuelDispenser(index)}>
													<div className="table-action-button table-action-danger-button">
														<i className="uil uil-times"></i>
													</div>
												</div>
											</td>
										</tr>
									))
									}
									<tr>
										<td className="td-input" style={{ height: '30px' }}>
											<input type="text" className="form-control text-center" name="quantity" value={selectedItem.quantity} autoComplete="off"
												onChange={(e) => setSelectedItem({ ...selectedItem, 'quantity': e.target.value.replace(/[^\d]/g, '') })}
												onFocus={handleFocus} />
										</td>
										<td className="td-input text-start" style={{ height: '30px' }}>
											<Select
												options={fuels}
												value={fuels.find(option => option.id === selectedItem.fuel_id)}
												onChange={(option) => selectFuel(option)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
												classNamePrefix="react-select"
											/>
										</td>
										<td className="td-input text-start" style={{ height: '30px' }}>
											<Select
												options={tanks}
												value={tanks.find(option => option.id === selectedItem.storage_tank_id)}
												onChange={(option) => setSelectedItem({ ...selectedItem, 'storage_tank_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
												classNamePrefix="react-select"
											/>
										</td>
										<td className="table-btn p-0">
											<button className="btn btn-primary w-100 h-100" onClick={() => addToList()}>
												{t('ADD')}
											</button>
										</td>
									</tr>
								</tbody>
							</table>
							<div className="d-flex w-100 mt-3">
								<button className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
								<button className="btn btn-primary w-100" onClick={(e) => createUpdate(e)}>{t('SAVE')}</button>
							</div>
						</div>
					}
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<DeleteModal
				modalDelete={showDeleteModal}
				setModalDelete={setShowDeleteModal}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index