import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { Modal } from 'react-bootstrap';
import Select from 'react-select'

import { GET } from 'helpers/api'

import { formatDateWithTime, sortByNumber } from 'helpers/helpers';
import useDidMountEffect from 'pages/parts/useDidMountEffect';
import ReportPrint from 'pages/components/ShiftReport';

function DailyReport({ showModal, setShowModal, selectedOperators }) {
	const { t } = useTranslation()

	const dispatch = useDispatch()

	const reduxSettings = useSelector(state => state.settings)
	const reduxReportData = useSelector(state => state.report)

	const printRef = useRef(null);

	const [activeTab, setActiveTab] = useState(1)
	const [fontSize, setFontSize] = useState(reduxSettings.fontSize ?? 9)

	const [shifts, setShifts] = useState([])

	const [filterData, setFilterData] = useState({
		shift_id: null
	})

	const [dailyReport, setDailyReport] = useState({
		general: {},
		data: [],
		shift: {},
	})

	const handlePrint = useReactToPrint({
		content: () => printRef.current
	});

	async function getDailyReport() {
		var copyData = { ...filterData }
		if (!copyData.shift_id) {
			copyData.shift_id = reduxReportData?.reportData?.shift?.id
		}
		const response = await GET('/daily-report', copyData, { loader: true })
		if (response != null) {
			response.total_sold = 0;
			response.total_tax = 0;
			response.payments = [];
			for (let i = 0; i < response.data.length; i++) {
				var item = response.data[i]
				if (response.data[i]?.dispensers?.length > 0) {
					response.data[i].dispensers = sortByNumber(response.data[i]?.dispensers)
				}
				if (item.total) {
					response.total_tax += Number(item?.total_sold) * Number(item?.tax)
					response.total_sold += item?.total_sold * item?.price
				} else {
					for (let j = 0; j < item?.payments?.length; j++) {
						if (i === 0) {
							item.payments[j].total = Number(item?.payments[j].amount) * Number(item?.price)
							item.payments[j].total_tax = Number(item?.payments[j].amount) * Number(item?.tax)
							response.payments.push(item?.payments[j])
						} else {
							response.payments[j].total += Number(item?.payments[j].amount) * Number(item?.price)
							response.payments[j].total_tax += Number(item?.payments[j].amount) * Number(item?.tax)
						}
					}
				}
			}
			// sortByNumber(response)
			setDailyReport(response)
			if (!copyData.shift_id) {
				setFilterData({ ...filterData, shift_id: response.shift.id })
			}

			// handlePrint()
		}
	}

	async function getShifts() {
		const response = await GET('/shift-helper')
		if (response.length > 0) {
			setShifts(response)
		}
	}

	useEffect(() => {
		if (showModal) {
			getShifts()
			getDailyReport()
		}
	}, [showModal]); // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getDailyReport()
	}, [filterData.shift_id]); // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch({ type: 'SET_SETTINGS', payload: { ...reduxSettings, fontSize: fontSize } })
	}, [fontSize]);

	return (
		<>
			<Modal show={showModal} animation={false} size='xl'
				centered dialogClassName="custom-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center justify-content-between w-100">
						<div className="d-flex align-items-center gap-3">
							<h4>{t('REPORT')}</h4>
							<div className="mx-2" style={{ width: '300px' }}>
								<Select
									options={shifts}
									value={shifts.find(option => option.id === filterData.shift_id)}
									onChange={(option) => setFilterData({ ...filterData, 'shift_id': option.id })}
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => (formatDateWithTime(option.opened_at) + ' - ' + formatDateWithTime(option.closed_at))}
									getOptionValue={(option) => option.id}
								/>
							</div>

							<div className="form-check">
								<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
									checked={activeTab === 1} onChange={() => setActiveTab(1)} />
								<label className="form-check-label" htmlFor="flexRadioDefault1">
									{t('FRONT_SIDE')}
								</label>
							</div>
							<div className="form-check">
								<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
									checked={activeTab === 2} onChange={() => setActiveTab(2)} />
								<label className="form-check-label" htmlFor="flexRadioDefault2">
									{t('backside')}
								</label>
							</div>

							<div className="mx-2" style={{ width: '75px' }}>
								<input type="text" className="form-control print-input" value={fontSize}
									onChange={(e) => setFontSize(e.target.value.replace(/[^0-9.]/g, ''))} />
							</div>
						</div>
						<button className="btn-rounded btn btn-primary me-2" onClick={() => handlePrint()}>
							<i className="uil uil-plus me-2"></i>
							{t('PRINT')}
						</button>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="card">
						<div className="card-body p-0">

							{/* {!dailyReport?.data?.length &&
								<div className="text-center">
									<button className="btn btn-primary" onClick={() => getDailyReport()}>
										{t('GENERATE_REPORT')}
									</button>
								</div>
							} */}

							{dailyReport?.data?.length > 0 &&
								<div className={"table-responsive " + (dailyReport?.data?.length ? '' : 'd-none')}>
									<ReportPrint dailyReport={dailyReport} activeTab={activeTab} printRef={printRef} selectedOperators={selectedOperators}
										fontSize={fontSize} />
								</div>
							}
						</div>
					</div>
				</Modal.Body>
			</Modal >
		</>
	)
}

export default DailyReport