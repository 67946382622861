import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line no-unused-vars
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import Modal from 'react-bootstrap/Modal';

import Header from 'containers/layout/director/Header';
import Footer from 'containers/layout/director/Footer';

import { SET_CALIBRATION, SET_REPORT_DATA } from 'store/report/actions';

import { formatMoneyWithCommas, countDecimals, formatDate, formatTime, hoursBetween, sortByNumber, formatMoney, updateReportData } from 'helpers/helpers'
import { GET, POST } from 'helpers/api';

// import { customStyles } from '../../helpers/selectStyle'
import 'assets/css/shiftReport.css'
import useDidMountEffect from 'pages/parts/useDidMountEffect';
import ShiftOpen from '../modals/ShiftOpen';

export default function ShiftReport() {
	const { t } = useTranslation()
	const { id } = useParams()

	registerLocale("ru", ru)

	const dispatch = useDispatch()

	const inputRefs = useRef([]);
	const timerRef = useRef(null);

	const reduxReportData = useSelector(state => state.report)
	// const reduxShiftData = useSelector(state => state.shiftData)
	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const [accuracyModal, setAccuracyModal] = useState(false)
	const [allChecked, setAllChecked] = useState(false)
	const [showShiftModal, setShowShiftModal] = useState(false)
	const [showOpenButton, setShowOpenButton] = useState(false)

	const [dispenserValue, setDispenserValue] = useState('')

	const [selectedFuel, setSelectedFuel] = useState({
		tanks: []
	})
	const [selectedTank, setSelectedTank] = useState({
		dispensers: [],
		disabled_fuel: true,
		disabled_dispenser: true,
	})
	const [selectedDispenser, setSelectedDispenser] = useState({})

	const [data, setData] = useState({
		fuels: [],
		payments: [],
		shift: {
			opened_at: '',
			closed_at: ''
		},
		for_services: '',
		arrival_per_shift: '',
		total_arrival_per_shift: '',
		cash: '',
		terminal: '',
		click: '',
		payme: '',
		uzum: '',
		cash_tax: '',
		terminal_tax: '',
		click_tax: '',
		payme_tax: '',
		uzum_tax: '',
		prev_balance: '',
		deposited_to_bank: '',
		current_balance: '',
		next_balance: '',
		current_dispenser_inaccuracy: '',
		total_dispenser_inaccuracy: '',
		current_fuel_inaccuracy: '',
		total_fuel_inaccuracy: '',
	})

	const [calibrations, setCalibrations] = useState([])

	function handleChangeGeneral(e) {
		var copyData = { ...data }
		copyData[e.target.name] = e.target.value.replace(/[^0-9.]/g, '')
		count(copyData)
	}

	function handleChangeSelectedTank(e) {
		var copyData = { ...data }
		var fuelIndex = copyData.fuels.findIndex(item => item.id === selectedFuel.id)
		var itemIndex = copyData?.fuels[fuelIndex]?.tanks.findIndex(item => item.id === selectedTank.id)
		var item = copyData?.fuels[fuelIndex]?.tanks[itemIndex]
		item[e.target.name] = e.target.value.replace(/[^0-9.-]/g, '')
		console.log(item);
		console.log(item[e.target.name]);
		setSelectedTank({ ...item })
	}

	function handleChangeFuel(e, index) {
		var copyData = { ...data }
		var item = { ...copyData.fuels[index] }
		item[e.target.name] = e.target.value.replace(/[^0-9.]/g, '')
		copyData.fuels[index] = item
		count(copyData)
	}

	function handleChangeFuelPayment(e, index, index2) {
		var copyData = { ...data }
		var item = { ...copyData.fuels[index].payments[index2] }
		item['amount'] = e.target.value.replace(/[^0-9.]/g, '')
		copyData.fuels[index].payments[index2] = item
		count(copyData)
	}

	function handleChangeTank(e, index) {
		var copyData = { ...data }
		var fuelIndex = copyData.fuels.findIndex(item => item.id === selectedFuel.id)
		var item = { ...copyData?.fuels[fuelIndex]?.tanks[index] }
		item[e.target.name] = e.target.value.replace(/[^0-9.]/g, '') || 0
		// if (e.target.name === 'fuel_metering') {
		// 	if (e.target.value?.length === 3 && !item?.fuel_metering.includes('.')) {
		// 		item.fuel_metering = e.target.value + '.'
		// 	} else {
		// 		item.fuel_metering = e.target.value
		// 	}
		// } else {
		// 	item[e.target.name] = e.target.value.replace(/[^0-9.]/g, '') || 0
		// }
		if (e.target.name === 'water_metering' && Number(item['water_metering']) > Number(item['fuel_metering'])) {
			item['water_metering'] = item['fuel_metering'];
		}
		copyData.fuels[fuelIndex].tanks[index] = item
		count(copyData)
	}

	const handleFocus = (event) => event.target.select();

	const handleKeyUp = (e) => {
		var targetInputRef;
		var indexType = 0
		var tabIndex = e.target.tabIndex;

		if (tabIndex >= 10 && tabIndex < 100) {
			indexType = 11
		}
		if (tabIndex >= 100 && tabIndex < 1000) {
			indexType = 100
		}
		if (tabIndex >= 1000) {
			indexType = 1000
		}
		if ((e.keyCode === 13 || e.keyCode === 39) && tabIndex) {
			targetInputRef = inputRefs?.current?.find(input => input?.tabIndex > tabIndex && !input?.disabled);
		}
		// if (e.keyCode === 37 && tabIndex) {
		// 	targetInputRef = inputRefs?.current.reverse()?.find(input => input?.tabIndex < tabIndex && !input?.disabled);
		// }

		if (e.keyCode === 40 && tabIndex) {
			targetInputRef = inputRefs.current.find((input) => input?.tabIndex === (tabIndex + indexType) && !input?.disabled);
		}

		if (e.keyCode === 38 && tabIndex) {
			targetInputRef = inputRefs?.current?.find((input) => input?.tabIndex === (tabIndex - indexType) && !input?.disabled);
		}
		if (targetInputRef) {
			targetInputRef?.focus();
		}

		if (e.keyCode === 13 && reduxSettings.auto_update === 1) {
			debounce()
		}
	};

	function debounce() {
		if (timerRef.current) {
			clearTimeout(timerRef.current)
		}
		timerRef.current = setTimeout(() => {
			updateCurrentShift()
		}, 5000);
	}

	async function updateCurrentShift() {
		var sendData = { ...reduxReportData.reportData }
		await POST('/update-current-shift', sendData, { loader: false })
		dispatch(SET_REPORT_DATA(updateReportData(reduxReportData)))
	}


	async function updateShift() {
		var sendData = { ...reduxReportData.reportData }
		var fuelIndex = sendData.fuels.findIndex(item => item.id === selectedFuel.id)
		var itemIndex = sendData?.fuels[fuelIndex]?.tanks.findIndex(item => item.id === selectedTank.id)
		var item = sendData?.fuels[fuelIndex]?.tanks[itemIndex]
		sendData.fuels[fuelIndex].tanks[itemIndex] = item
		await POST('/update-shift', sendData, { loader: false })
		dispatch(SET_REPORT_DATA(updateReportData(reduxReportData)))
		setAccuracyModal(false)
		if (id) {
			getPrevFuels()
		} else {
			getFuels()
		}
	}

	function changeDispensersValues(type) {
		var copyData = { ...data }
		var fuelIndex = copyData.fuels.findIndex(item => item.id === selectedFuel.id)
		var tankIndex = copyData.fuels[fuelIndex].tanks.findIndex(item => item.id === selectedTank.id)
		var dispensers = copyData.fuels[fuelIndex].tanks[tankIndex].dispensers
		for (let i = 0; i < dispensers.length; i++) {
			if (dispensers[i].checked) {
				dispensers[i].checked = false;
				if (type === '+') {
					dispensers[i].beginning = Number(dispensers[i].beginning) + Number(dispenserValue);
					dispensers[i].beginning_difference = Number(dispensers[i].beginning) - Number(dispensers[i].beginning_original);
					dispensers[i].final = Number(dispensers[i].final) + Number(dispenserValue);
				} else {
					dispensers[i].beginning = Number(dispensers[i].beginning) - Number(dispenserValue);
					dispensers[i].beginning_difference = Number(dispensers[i].beginning) - Number(dispensers[i].beginning_original);
					dispensers[i].final = Number(dispensers[i].final) - Number(dispenserValue);
				}
			}
		}
		setAllChecked(false)
		setDispenserValue('')
		setData(copyData)
	}

	function setAllDispensersChecked(value) {
		var copyData = { ...data }
		var fuelIndex = copyData.fuels.findIndex(item => item.id === selectedFuel.id)
		var tankIndex = copyData.fuels[fuelIndex]?.tanks?.findIndex(item => item.id === selectedTank.id)
		var dispensers = copyData.fuels[fuelIndex]?.tanks[tankIndex].dispensers
		for (let index = 0; index < dispensers.length; index++) {
			dispensers[index].checked = value;
		}
		setAllChecked(value)
		setData(copyData)
	}

	function changeDispensersChecked(value, index) {
		var copyData = { ...data }
		var fuelIndex = copyData.fuels.findIndex(item => item.id === selectedFuel.id)
		var tankIndex = copyData.fuels[fuelIndex].tanks.findIndex(item => item.id === selectedTank.id)
		var dispensers = copyData.fuels[fuelIndex].tanks[tankIndex].dispensers
		dispensers[index].checked = value;
		setData(copyData)
	}

	function handleChangeDispenser(e, index) {
		var copyData = { ...data }
		var fuelIndex = copyData.fuels.findIndex(item => item.id === selectedFuel.id)
		var tankIndex = copyData.fuels[fuelIndex].tanks.findIndex(item => item.id === selectedTank.id)

		var item = { ...copyData.fuels[fuelIndex].tanks[tankIndex].dispensers[index] }
		item[e.target.name] = e.target.value.replace(/-[^0-9.]/g, '')
		if (e.target.name === 'final') {
			item['sold'] = Number(item['final']) - Number(item['beginning'] ?? 0)
		}
		if (e.target.name === 'sold') {
			item['final'] = Number(item['sold']) + Number(item['beginning'] ?? 0)
		}
		if (e.target.name === 'beginning') {
			item['beginning_difference'] = Number(item['beginning'] ?? 0) - Number(item['beginning_original'])
			item['final'] = Number(item['beginning'] ?? 0) + Number(item['sold'])
		}
		copyData.fuels[fuelIndex].tanks[tankIndex].dispensers[index] = item
		count(copyData)
	}

	function count(copyData) {
		console.log(copyData);
		copyData.payments = [];
		copyData.cash = 0;
		copyData.total = 0;
		copyData.admission = 0;
		copyData.arrival_per_shift = 0;
		copyData.current_dispenser_inaccuracy = 0;
		copyData.current_fuel_inaccuracy = Number(copyData.current_fuel_inaccuracy_original);
		for (let i = 0; i < copyData?.fuels?.length; i++) {
			var fuel = copyData?.fuels[i]
			fuel.sold = 0
			fuel.prev_balance = 0
			fuel.admission = 0

			for (let j = 0; j < fuel.tanks.length; j++) {
				const tank = fuel.tanks[j]
				// Продано общее для резервуара
				tank.sold = 0;
				tank.current_dispenser_inaccuracy = 0;
				tank.current_fuel_inaccuracy = 0;
				// tank.total_dispenser_inaccuracy = 0;
				for (let k = 0; k < tank.dispensers.length; k++) {
					var dispenser = tank.dispensers[k];
					if (dispenser?.sold) {
						tank.sold += Number(dispenser?.sold)
					}
					// Текущая общая пошрешность ТРК
					if (dispenser?.inaccuracy) {
						copyData.current_dispenser_inaccuracy += Number(dispenser?.inaccuracy)
						tank.current_dispenser_inaccuracy += Number(dispenser?.inaccuracy)
					}
				}

				if (tank.id) {
					// Общий замер
					tank.general_metering = Number(tank.fuel_metering) - Number(tank.water_metering);
					// В резервуаре
					if (tank.fuel_metering) {
						if (tank.water_metering) {
							tank.general_metering = (tank.fuel_metering - Number(tank.water_metering)).toFixed(1);
						} else {
							tank.general_metering = tank.fuel_metering;
						}
						var calibrationsCopy = [...calibrations]
						if (calibrationsCopy.length === 0) {
							calibrationsCopy = reduxReportData.calibrations ?? [];
						}
						if (calibrationsCopy.length > 0) {
							var tankCalibrations = calibrationsCopy?.filter(item => item.storage_tank_id === tank.id)
							var calibration = tankCalibrations?.find(item => Number(item.height) === Math.floor(Number(tank.general_metering)))
							if (calibration) {
								var decimalStr = tank?.general_metering?.toString()?.split('.')[1];
								tank.in_storage_tank = ((Number(calibration.capacity ?? 0)) + (Number(decimalStr ?? 0) * Number(calibration.density)));
								if (reduxSettings.rounding === 'round') {
									tank.in_storage_tank = Math.round(tank.in_storage_tank)
								} else if (reduxSettings.rounding === 'floor') {
									tank.in_storage_tank = Math.floor(tank.in_storage_tank)
								} else if (reduxSettings.rounding === 'ceil') {
									tank.in_storage_tank = Math.ceil(tank.in_storage_tank)
								} else {
									tank.in_storage_tank = Math.round(tank.in_storage_tank)
								}
							} else {
								tank.in_storage_tank = 0
							}
						}
					} else {
						tank.in_storage_tank = 0
					}
					if (countDecimals(tank?.general_metering) >= 3) {
						// tank.general_metering = tank.general_metering
					}
					// Итого по факту
					tank.total_actual = Number(tank.in_storage_tank) + Number(tank.in_pipiline)
					// Итого по бугх.
					tank.total_by_accounting = (Number(tank.prev_balance) + Number(tank.admission)) - Number(tank.sold)
					// Недостача
					tank.lack = (Number(tank.total_actual) - Number(tank.total_by_accounting));
				}
				// Постпуление
				fuel.admission += Number(tank.admission ?? 0);
				// Остаток Предыдущий
				fuel.prev_balance += Number(tank.prev_balance ?? 0);
				// Погрещность постпуления
				copyData.current_fuel_inaccuracy += (Number(tank.admission ?? 0) * Number(reduxSettings.loss ?? 0) / 100);
				tank.current_fuel_inaccuracy += Math.round(Number(tank.admission ?? 0) * Number(reduxSettings.loss ?? 0) / 100);
				tank.total_fuel_inaccuracy = Math.round(tank.total_fuel_inaccuracy_original ?? 0) + Math.round(tank.current_fuel_inaccuracy ?? 0)
				// tank.total_dispenser_inaccuracy = Math.round(tank.total_dispenser_inaccuracy_original ?? 0) + Math.round(tank.current_dispenser_inaccuracy ?? 0)
				// Продано общее для топлива
				fuel.sold += Number(tank.sold)

				// if (selectedTank?.id === tank.id) {
				// 	setSelectedTank(tank)
				// }
			}
			// Отстаток переходящий
			fuel.next_balance = (Number(fuel.prev_balance) + Number(fuel.admission)) - Number(fuel.sold)
			// Сумма
			fuel.amount = Number(fuel.price) * Number(fuel.sold)
			// Приход за смену
			copyData.arrival_per_shift += fuel.amount
			copyData.total_arrival_per_shift = copyData.for_services;

			for (let j = 0; j < fuel?.payments?.length; j++) {
				var payment = fuel?.payments[j];
				if (j === 0) {
					payment.amount = Number(fuel.sold)
					copyData.cash += Number(fuel.sold) * Number(fuel.price)
				} else {
					fuel.payments[0].amount -= Number(payment.amount)
					copyData.cash -= Number(payment.amount) * Number(fuel.price)
				}

				if (j === 0) {
					if (i === 0) {
						payment.total = Number(payment.amount) * Number(fuel?.price)
						copyData.payments.push(payment)
					} else {
						copyData.payments[0].total += Number(payment.amount) * Number(fuel?.price)
					}
				}

				if (i === 0) {
					if (j !== 0) {
						payment.total = Number(payment.amount) * Number(fuel?.price)
						copyData.payments.push(payment)
						copyData.payments[0].total -= Number(payment.amount) * Number(fuel.price)
					}
				} else {
					if (j !== 0) {
						copyData.payments[j].total += Number(payment.amount) * Number(fuel?.price)
						copyData.payments[0].total -= Number(payment.amount) * Number(fuel.price)
					}
				}
			}
		}

		// copyData.cash -= Number(copyData.terminal);
		// copyData.cash -= Number(copyData.payme);
		// copyData.cash -= Number(copyData.click);
		// copyData.cash -= Number(copyData.uzum);

		copyData.current_fuel_inaccuracy = Math.round(copyData.current_fuel_inaccuracy)
		copyData.total_fuel_inaccuracy = Math.round(copyData.total_fuel_inaccuracy_original ?? 0) + Math.round(copyData.current_fuel_inaccuracy ?? 0)

		copyData.next_balance = Number(copyData.prev_balance) + Number(copyData.cash) - Number(copyData.deposited_to_bank);
		copyData.current_balance = copyData.for_services;
		setData(copyData)
	}

	function selectFuel(item) {
		setSelectedFuel(item)
		selectTank(item?.tanks[0])
	}

	function selectTank(item) {
		item.dispensers = sortByNumber(item.dispensers);
		setSelectedTank(item)
		selectDispenser(item?.dispensers[0])
	}

	function selectDispenser(item) {
		setSelectedDispenser(item)
	}

	function clearData() {
		setData({
			fuels: [],
			shift: {
				opened_at: '',
				closed_at: ''
			},
			for_services: 0,
			arrival_per_shift: 0,
			total_arrival_per_shift: 0,
			cash: 0,
			terminal: 0,
			click: 0,
			payme: 0,
			uzum: 0,
			cash_tax: 0,
			terminal_tax: 0,
			click_tax: 0,
			payme_tax: 0,
			uzum_tax: 0,
			prev_balance: 0,
			deposited_to_bank: 0,
			current_balance: 0,
			next_balance: 0,
			current_dispenser_inaccuracy: 0,
			total_dispenser_inaccuracy: 0,
			current_fuel_inaccuracy: 0,
			total_fuel_inaccuracy: 0,
		})
		setSelectedFuel({
			tanks: []
		})
		setSelectedTank({
			dispensers: []
		})
		setShowOpenButton(true)
	}

	async function getFuels() {
		// if (reduxReportData.reportData && reduxReportData.reportData?.fuels?.length && reduxShiftData?.id) {
		// 	setData({ ...data, ...reduxReportData.reportData })
		// 	setSelectedFuel(reduxReportData.reportData?.fuels[0])
		// 	setSelectedTank(reduxReportData.reportData?.fuels[0]?.tanks[0])
		// 	setSelectedDispenser(reduxReportData.reportData?.fuels[0]?.tanks[0]?.dispensers[0])
		// 	return;
		// }
		const response = await GET('/shift-fuel', {}, { loader: true })
		if (response) {
			if (showOpenButton) {
				setShowOpenButton(false)
			}
			count({ ...data, ...response })
			if (response?.fuels?.length) {
				setSelectedFuel(response?.fuels[0])
				response.fuels[0].tanks[0].dispensers = sortByNumber(response?.fuels[0]?.tanks[0].dispensers);
				setSelectedTank(response?.fuels[0]?.tanks[0])
				setSelectedDispenser(response?.fuels[0]?.tanks[0]?.dispensers[0])
			}
		} else {
			clearData()
		}
	}

	async function getPrevFuels() {
		const response = await GET(`/shift-fuel?offset=${id}`)
		if (response) {
			if (showOpenButton) {
				setShowOpenButton(false)
			}
			count({ ...data, ...response })
			setSelectedFuel(response?.fuels[0])
			response.fuels[0].tanks[0].dispensers = sortByNumber(response?.fuels[0]?.tanks[0].dispensers);
			setSelectedTank(response?.fuels[0]?.tanks[0])
			setSelectedDispenser(response?.fuels[0]?.tanks[0]?.dispensers[0])
		}
	}

	async function getCalibration() {
		const response = await GET('/calibration-helper')
		if (response) {
			setCalibrations(response)
			dispatch(SET_CALIBRATION(response))
		}
	}

	useEffect(() => {
		if (id) {
			getPrevFuels()
		} else {
			getFuels()
		}
		getCalibration()
	}, [id]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		if (id) {
			getPrevFuels()
		} else {
			getFuels()
		}
	}, [reduxReportData.updateShift]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(SET_REPORT_DATA(data))
	}, [data]) // eslint-disable-line react-hooks/exhaustive-deps

	// useEffect(() => {
	// 	if (data.fuels.length > 0) {
	// 		count(data)
	// 	}
	// }, [selectedTank]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Header />
			{showOpenButton &&
				<div className="container-fluid shiftReport__container d-flex align-items-center justify-content-center" style={{ height: '80vh' }}>
					<button className="btn btn-primary" onClick={() => setShowShiftModal(true)}>
						{t('OPEN_SHIFT')}
					</button>
				</div>
			}
			{reduxReportData.reportData?.shift?.id &&
				<div className='container-fluid shiftReport__container'>
					<div className='card mb-1 general__container'>
						{/* <b className='mx-2 mb-1'>{t('GENERAL_INFORMATION')}</b> */}
						<div className='row'>
							<div className='col-md-2'>
								<div className='general__field'>
									<span>{t('POS')}</span>
									<input type="text" className='small__input' autoComplete="off"
										name="arrival_per_shift" value={reduxAccount?.pos_name} disabled />
								</div>
								<div className='general__field'>
									<span>{t('DATE')}</span>
									<div>
										<input type="text" className='small__input' autoComplete="off"
											name="arrival_per_shift" value={formatDate(data.shift.opened_at)}
											onChange={(e) => handleChangeGeneral(e)} disabled />
									</div>
								</div>
								<div className='general__field'>
									<span>{t('SHIFT')}</span>
									<input type="text" className='time__input me-0' disabled
										value={formatTime(data.shift.opened_at)} />
								</div>
								<div className='general__field'>
									<span>{t('DURATION')} (ч.)</span>
									<input type="text" className='time__input me-0' disabled
										value={hoursBetween(data.shift.opened_at, data.shift.closed_at)} />
								</div>
							</div>

							<div className='col-md-2'>
								<div className='general__field'>
									<span>{t('for_services')}</span>
									<input type="text" className='small__input' autoComplete="off"
										name="for_services" value={formatMoneyWithCommas(data.for_services)}
										onChange={(e) => handleChangeGeneral(e)} onFocus={handleFocus} />
								</div>
								<div className='general__field'>
									<span>{t('sold_per_shift')}</span>
									<input type="text" className='small__input' autoComplete="off"
										name="arrival_per_shift" value={formatMoneyWithCommas(data.arrival_per_shift)}
										onChange={(e) => handleChangeGeneral(e)} onFocus={handleFocus} />
								</div>
								<div className='general__field'>
									<span>{t('total_sold_per_shift')}</span>
									<input type="text" className='small__input' autoComplete="off"
										name="total_arrival_per_shift" value={formatMoneyWithCommas(data.total_arrival_per_shift)}
										onChange={(e) => handleChangeGeneral(e)} onFocus={handleFocus} />
								</div>
							</div>

							<div className='col-md-2 offset-md-1'>
								<div className='general__field'>
									<span>{t('balance_previous')}</span>
									<input type="text" className='small__input' autoComplete="off"
										name="prev_balance" value={formatMoneyWithCommas(data.prev_balance)}
										onChange={(e) => handleChangeGeneral(e)} onFocus={handleFocus} />
								</div>
								<div className='general__field'>
									<span>{t('delivered_to_the_bank')}</span>
									<input type="text" className='small__input' autoComplete="off"
										name="deposited_to_bank" value={formatMoneyWithCommas(data.deposited_to_bank)}
										onChange={(e) => handleChangeGeneral(e)} onFocus={handleFocus} />
								</div>
								<div className='general__field'>
									<span>{t('balance_current')}</span>
									<input type="text" className='small__input' autoComplete="off"
										name="current_balance" value={formatMoneyWithCommas(data.current_balance)}
										onChange={(e) => handleChangeGeneral(e)} onFocus={handleFocus} />
								</div>
								<div className='general__field'>
									<span>{t('remaining_carryover')}</span>
									<input type="text" className='small__input' autoComplete="off"
										name="next_balance" value={formatMoneyWithCommas(data.next_balance)}
										onChange={(e) => handleChangeGeneral(e)} onFocus={handleFocus} />
								</div>
							</div>

							<div className="col-md-5">
								<table className="table table-bordered report_table">
									<thead>
										<tr className="sold__table--th">
											{data?.payments?.map((item, index) => (
												<th key={index}>
													{item.payment_name}
												</th>
											))
											}
										</tr>
									</thead>
									<tbody>
										<tr>
											{data?.payments?.map((item, index) => (
												<td key={index}>
													{formatMoney(item.total)}
												</td>
											))
											}
										</tr>
									</tbody>
								</table>
								{/* <div className="row">
								{data?.payments?.map((item, index) => (
									<div className="col-md-6 mb-1" key={index}>
										<div className='general__field'>
											<span>{item.payment_name}</span>
											<input type="text" className='small__input' autoComplete="off"
												name="next_balance" value={formatMoneyWithCommas(item.total)}
												onChange={(e) => handleChangeGeneral(e)} onFocus={handleFocus} />
										</div>
									</div>
								))
								}
							</div> */}
							</div>
						</div>
					</div>
					<div className='card mb-1 sale__container'>
						<b className='mx-2'>{t('SOLD_PER_SHIFT')}</b>
						<div className='mb-1'>
							<div className='table-responsive mx-2 sold__table--height sticky__position--table' >
								<table className='table table-sm table-bordered mb-0'>
									<thead>
										<tr className='sold__table--th'>
											<th style={{ width: '3%' }} className='text-left'>{t('FUEL')}</th>
											<th style={{ width: '8%' }}>{t('RESIDUE')}<br />{t('previous')}</th>
											<th style={{ width: '5%' }}>{t('ADMISSION')}</th>
											<th style={{ width: '5%' }}>{t('PRICE')}</th>
											<th style={{ width: '5%' }}>{t('SOLD')}</th>
											<th style={{ width: '8%' }}>{t('AMOUNT')}</th>
											{data.fuels[0]?.payments?.map((item, index) => (
												<th style={{ width: '5%' }} key={index}>{item.payment_name}</th>
											))}
											<th style={{ width: '8%' }}>{t('RESIDUE')}<br />{t('previous')}</th>
											<th style={{ width: '5%' }}>{t('Налог за литр')}</th>
											<th style={{ width: '8%' }}>{t('tax')}</th>
										</tr>
									</thead>
									<tbody className='sold__table--td '>
										{data.fuels.map((item, index) => (
											<tr key={index} className={selectedFuel.id === item.id ? 'tr-active' : ''} onClick={() => selectFuel(item)}>
												<td className='text-left cursor'>
													{item.name}
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.prev_balance)}
														name="prev_balance" onChange={(e) => handleChangeFuel(e, index)} autoComplete="off" disabled />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.admission)}
														name="admission" onChange={(e) => handleChangeFuel(e, index)} autoComplete="off" disabled />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.price)}
														name="price" onChange={(e) => handleChangeFuel(e, index)} autoComplete="off" onFocus={handleFocus}
														onKeyUp={(e) => handleKeyUp(e)} tabIndex={(index + 1) * 11 + 1}
														ref={(inputRef) => (inputRefs.current[(index + 1) * 11 + 1] = inputRef)}
													/>
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.sold)}
														name="sold" onChange={(e) => handleChangeFuel(e, index)} autoComplete="off" disabled />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoney(item.amount)}
														name="amount" onChange={(e) => handleChangeFuel(e, index)} autoComplete="off" disabled />
												</td>
												{item?.payments?.map((item2, index2) => (
													<td className="td-input" key={index2}>
														<input type="text" className="form-control" value={formatMoneyWithCommas(item2.amount)} onFocus={handleFocus}
															name="amount" onChange={(e) => handleChangeFuelPayment(e, index, index2)} autoComplete="off"
															onKeyUp={(e) => handleKeyUp(e)} tabIndex={(index + 1) * 11 + index2 + 1}
															ref={(inputRef) => index2 !== 0 ? (inputRefs.current[(index + 1) * 11 + index2 + 1] = inputRef) : null}
															disabled={index2 === 0} />
													</td>
												))}
												<td className={"td-input " + (item.next_balance < 0 && 'error')}>
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.next_balance)} disabled
														name="next_balance" autoComplete="off"
													/>
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoney(item.tax)}
														name="tax" onChange={(e) => handleChangeFuel(e, index)} autoComplete="off" onFocus={handleFocus}
														onKeyUp={(e) => handleKeyUp(e)} tabIndex={(index + 1) * 11 + item?.payments.length + 1}
														ref={(inputRef) => (inputRefs.current[(index + 1) * 11 + item?.payments.length + 1] = inputRef)}
													/>
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={(Number(item.sold) * Number(item.tax))} disabled
														name="tax" autoComplete="off" onFocus={handleFocus} />
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className='card mb-1 storage__container'>
						<b className='mx-2'>{t('tank_indicators')}</b>
						<div className='p-1 py-0'>
							<div className='table-responsive sticky__position--table w-100 h-100'>
								<table className='table table-bordered table-sm mb-1'>
									<thead>
										<tr className='sold__table--th bg-lightgray'>
											<th>{t('STORAGE_TANK')}</th>
											<th>{t('RESIDUE')}<br />{t('previous')}</th>
											<th>{t('admission')}</th>
											<th>{t('SOLD')}</th>
											<th>{t('metering')}<br />{t('FUEL')}({t('sm')})</th>
											<th>{t('metering')}<br />{t('water')}</th>
											<th>{t('general')}<br />{t('metering')}</th>
											<th title='(высота(см) * обьем) + (высота(мм) * плотность)'>{t('in')}<br />{t('tank')}</th>
											<th>{t('in')}<br />{t('pipilines')}</th>
											<th>{t('TOTAL')}<br />(по факту)</th>
											<th>{t('TOTAL')}<br />(по бухг.)</th>
											<th>{t('shortage')}</th>
										</tr>
									</thead>
									<tbody>
										{selectedFuel?.tanks?.map((item, index) => (
											<tr className={selectedTank.id === item.id ? 'tr-active' : ''} key={index}>
												<td className='cursor' onClick={() => selectTank(item)}>
													{item.name}
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.prev_balance)}
														name="prev_balance" onChange={(e) => handleChangeTank(e, index)} autoComplete="off" onFocus={handleFocus}
														onKeyUp={(e) => handleKeyUp(e)} tabIndex={(index + 1) * 100 + 1}
														ref={(inputRef) => (inputRefs.current[(index + 1) * 100 + 1] = inputRef)}
													/>
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.admission)}
														name="admission" onChange={(e) => handleChangeTank(e, index)} autoComplete="off" onFocus={handleFocus}
														onKeyUp={(e) => handleKeyUp(e)} tabIndex={(index + 1) * 100 + 2}
														ref={(inputRef) => (inputRefs.current[(index + 1) * 100 + 2] = inputRef)} />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.sold)}
														name="sold" onChange={(e) => handleChangeTank(e, index)} autoComplete="off" disabled />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={(item.fuel_metering)}
														name="fuel_metering" onChange={(e) => handleChangeTank(e, index)} autoComplete="off" onFocus={handleFocus}
														onKeyUp={(e) => handleKeyUp(e)} tabIndex={(index + 1) * 100 + 3}
														ref={(inputRef) => (inputRefs.current[(index + 1) * 100 + 3] = inputRef)} />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.water_metering)}
														name="water_metering" onChange={(e) => handleChangeTank(e, index)} autoComplete="off" onFocus={handleFocus}
														onKeyUp={(e) => handleKeyUp(e)} tabIndex={(index + 1) * 100 + 4}
														ref={(inputRef) => (inputRefs.current[(index + 1) * 100 + 4] = inputRef)}
														disabled={!item.fuel_metering} />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.general_metering)}
														name="general_metering" onChange={(e) => handleChangeTank(e, index)} autoComplete="off" disabled />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={item.in_storage_tank}
														name="in_storage_tank" onChange={(e) => handleChangeTank(e, index)} autoComplete="off" disabled />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.in_pipiline ?? 0)}
														name="in_pipiline" onChange={(e) => handleChangeTank(e, index)} autoComplete="off" disabled />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.total_actual)}
														name="total_actual" onChange={(e) => handleChangeTank(e, index)} autoComplete="off" disabled />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={formatMoneyWithCommas(item.total_by_accounting)}
														name="total_by_accounting" onChange={(e) => handleChangeTank(e, index)} autoComplete="off" disabled />
												</td>
												<td className="td-input">
													<input type="text" className="form-control" value={(item.lack > 0 ? '-' : '') + formatMoneyWithCommas(Math.abs(item.lack))}
														name="lack" onChange={(e) => handleChangeTank(e, index)} autoComplete="off" disabled />
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className='card trk__container'>
						<b className='mx-2'>{t('dispenser_indicators')}</b>
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-8">
									<div className='table-responsive mx-2 sticky__position--table w-100 h-100'>
										<table className='table table-bordered table-sm mb-1'>
											<thead>
												<tr className='sold__table--th'>
													<th>{t('FUEL_DISPENSER')}</th>
													<th>{t('finally')}</th>
													<th>{t('to_beginning')}</th>
													<th>{t('SOLD')}</th>
													<th>{t('inaccuracy')}</th>
													<th>
														<div className="d-center">
															<label className="custom-checkbox my-1">
																<input type="checkbox" checked={allChecked}
																	onChange={(e) => setAllDispensersChecked(e.target.checked)} />
																<span className="checkmark"></span>
															</label>
														</div>
													</th>
												</tr>
											</thead>
											<tbody>
												{selectedTank?.dispensers?.map((item, i) => (
													<tr className={selectedDispenser.id === item.id ? 'tr-active' : ''} key={i}>
														<td className='cursor' onClick={() => selectDispenser(item)}>
															{item.name}
														</td>
														<td className="td-input">
															<input type="text" className="form-control" value={(item.final)}
																name="final" onChange={(e) => handleChangeDispenser(e, i)} autoComplete="off" onFocus={handleFocus}
																onKeyUp={(e) => handleKeyUp(e)} tabIndex={(i + 1) * 1000 + 1}
																ref={(inputRef) => (inputRefs.current[(i + 1) * 1000 + 1] = inputRef)}
															/>
														</td>
														<td className="td-input">
															<input type="text" className="form-control" value={(item.beginning ?? 0)}
																name="beginning" onChange={(e) => handleChangeDispenser(e, i)} autoComplete="off" onFocus={handleFocus}
																onKeyUp={(e) => handleKeyUp(e)} tabIndex={(i + 1) * 1000 + 2}
																ref={(inputRef) => (inputRefs.current[(i + 1) * 1000 + 2] = inputRef)} />
														</td>
														<td className="td-input">
															<input type="text" className="form-control" value={(item.sold)}
																name="sold" onChange={(e) => handleChangeDispenser(e, i)} autoComplete="off" onFocus={handleFocus}
																onKeyUp={(e) => handleKeyUp(e)} tabIndex={(i + 1) * 1000 + 3}
																ref={(inputRef) => (inputRefs.current[(i + 1) * 1000 + 3] = inputRef)} />
														</td>
														<td className="td-input">
															<input type="text" className="form-control" value={formatMoneyWithCommas(item.inaccuracy)}
																name="inaccuracy" onChange={(e) => handleChangeDispenser(e, i)} autoComplete="off" onFocus={handleFocus}
																onKeyUp={(e) => handleKeyUp(e)} tabIndex={(i + 1) * 1000 + 4}
																ref={(inputRef) => (inputRefs.current[(i + 1) * 1000 + 4] = inputRef)}
															/>
														</td>
														<td className="td-input">
															<div className="d-center">
																<label className="custom-checkbox my-1">
																	<input type="checkbox" checked={item.checked}
																		onChange={(e) => changeDispensersChecked(e.target.checked, i)} />
																	<span className="checkmark"></span>
																</label>
															</div>
														</td>
													</tr>
												))
												}
												{selectedTank?.dispensers.length > 0 &&
													<tr className="tr-bold">
														<td>{t('TOTAL')}</td>
														<td></td>
														<td></td>
														<td>{selectedTank.sold}</td>
														<td>{selectedTank.current_dispenser_inaccuracy}</td>
														<td></td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</div>
								<div className="col-md-4">
									<div className='general__field'>
										<span>{t('current_fuel_inaccuracy')}</span>
										<input type="text" className='small__input' autoComplete="off"
											name="current_fuel_inaccuracy" value={formatMoneyWithCommas(selectedTank?.current_fuel_inaccuracy)}
											onChange={(e) => handleChangeGeneral(e)} disabled />
									</div>
									<div className='general__field' onClick={() => setAccuracyModal(true)}>
										<span>{t('total_fuel_inaccuracy')}</span>
										<input type="number" className='small__input' autoComplete="off"
											name="total_fuel_inaccuracy" value={selectedTank?.total_fuel_inaccuracy}
											onChange={(e) => handleChangeSelectedTank(e)} />
									</div>
									<div className='general__field'>
										<span>{t('current_dispenser_inaccuracy')}</span>
										<input type="text" className='small__input' autoComplete="off"
											name="current_dispenser_inaccuracy" value={formatMoneyWithCommas(selectedTank?.current_dispenser_inaccuracy)}
											onChange={(e) => handleChangeGeneral(e)} disabled />
									</div>
									<div className='general__field' onClick={() => setAccuracyModal(true)}>
										<span>{t('total_dispenser_inaccuracy')}</span>
										<input type="number" className='small__input' autoComplete="off"
											name="total_dispenser_inaccuracy" value={selectedTank?.total_dispenser_inaccuracy}
											onChange={(e) => handleChangeSelectedTank(e)} />
									</div>
									<div className='general__field'>
										<input type="number" className="trk_input" value={dispenserValue}
											onChange={(e) => setDispenserValue(e.target.value)} />
										<div className="d-flex">
											<button
												className='btn btn-danger btn-sm me-2'
												style={{ width: '100px' }}
												onClick={() => changeDispensersValues('-')} >
												{t('subtract')}
											</button>
											<button
												className='btn btn-primary btn-sm'
												style={{ width: '100px' }}
												onClick={() => changeDispensersValues('+')} >
												{t('add')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}

			<Footer />

			<ShiftOpen showModal={showShiftModal} setShowModal={setShowShiftModal} />

			<Modal
				show={accuracyModal}
				onHide={() => setAccuracyModal(false)}
				keyboard={false}
				size='md'
			>
				<Modal.Header className='px-4 py-2' closeButton>
					<Modal.Title>{t('inaccuracy')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<label>{t('total_fuel_inaccuracy')}</label>
						<input
							type="text"
							className='form-control'
							name="total_fuel_inaccuracy"
							value={selectedTank.total_fuel_inaccuracy}
							onChange={(e) => handleChangeSelectedTank(e)}
							onFocus={handleFocus}
						/>
					</div>
					<div className="form-group">
						<label>{t('total_dispenser_inaccuracy')}</label>
						<input
							type="text"
							className='form-control'
							name="total_dispenser_inaccuracy"
							value={selectedTank.total_dispenser_inaccuracy}
							onChange={(e) => handleChangeSelectedTank(e)}
							onFocus={handleFocus}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex w-100 gap-3">
						<button className="btn btn-danger w-100" onClick={() => setAccuracyModal(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" variant="primary" onClick={() => updateShift()}>
							{t('SAVE')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}